<template >
    <div>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :disable-pagination=true
            :loading=loadingTable
            :disable-sort="$vuetify.breakpoint.name == 'xs'"
            :key="`list-supplier-qoutes-${tenantId}`"
            :options.sync="options"
            hide-default-footer
            loading-text="Cargando cotizaciones..."
            no-results-text="No se han encontrado cotizaciones"
            no-data-text="Sin cotizaciones"
            class="elevation-1"
            dense
        >
            <template v-slot:top>
                <ExpandableFilters 
                    title="COTIZACIONES"
                    :filters=filters
                    titleButton="Nueva <span class='d-md-inline d-none'>cotización</span>"
                    iconToolbar='mdi-package-up'
                    classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0'
                    :actionButton="manageQuote"
                    @datafilters=getSupplierQuotes
                />
            </template>
            <template v-slot:item.actions="{item}">
                <v-tooltip 
                    bottom 
                    v-if="(item.status_id=='QUO_APPROVED' || item.status_id=='QUO_ORDERED') &&  !item.status_quote_payment"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on" 
                            icon
                            color='warning'
                            @click="requestAdvance(item)"
                        ><v-icon>mdi-cash</v-icon></v-btn>
                    </template>
                        <span>Solicitar anticipo</span>
                </v-tooltip> 
                <span v-if="(item.status_id=='QUO_APPROVED' || item.status_id=='QUO_ORDERED') &&  !!item.status_quote_payment">
                    Solicitud anticipo enviada
                </span> 
            </template>
            <template v-slot:item.total="{item}">
                ${{item.total}}
            </template>
            <template v-slot:footer>
                <v-pagination
                    class='mt-10'
                    v-model=currentPage
                    :length=totalItems
                    @input="handlePageChange"
                    total-visible="15"
                ></v-pagination>
            </template>
        </v-data-table>
        <v-dialog 
            v-model="dialog"
            persistent 
            max-width="500px"
        >
            <v-card>
                <v-form ref='form_request_advance'>
                    <v-col>
                        <v-card tile class='text-center' color='blue-grey lighten-1 white--text py-2 mx-4'>
                            Solicitud de anticipo a proveedor
                        </v-card>
                        <v-row>
                            <v-col cols=12>
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon >mdi-package-up</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>COTIZACIÓN #: </b> {{advanceQuote.quote_id}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>TOTAL : </b> ${{advanceQuote.total}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>RETENCIÓN IVA : </b> ${{advanceQuote.retiva}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>     
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>RETENCIÓN RENTA : </b> ${{advanceQuote.retrenta}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>     
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-cash-usd</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>A PAGAR : </b> ${{advanceQuote.a_pagar}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>          
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-account-circle</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>PROVEEDOR: </b></v-list-item-title>
                                    <v-list-item-subtitle> {{advanceQuote.name}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                    <v-list-item-icon class='mr-2'>
                                        <v-icon>mdi-account-circle</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title><b>FORMA DE PAGO: </b></v-list-item-title>
                                    <v-list-item-subtitle> {{advanceQuote.payment_method}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>          
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols=12  class='pb-0 col-md-5'> 
                                <v-text-field
                                    v-model=advanceQuote.monto
                                    label="Monto anticipo"
                                    outlined 
                                    type="number"
                                    dense
                                    :rules=minVal
                                    class="px-4"
                                ></v-text-field>
                            </v-col>
                            <v-col cols=12 class='pb-0 col-md-7'> 
                                <v-select 
                                    v-model=advanceQuote.fin_account_id
                                    :items=finAccounts
                                    item-value="fin_account_id"
                                    item-text="fin_account_name"
                                    label="Cuenta de origen del débito"
                                    outlined
                                    class="px-4 pl-md-0"
                                    dense
                                    :rules=required
                                />
                            </v-col>
                            <v-col cols=12 class='pt-0'>
                                <v-textarea
                                    v-model=advanceQuote.description
                                    outlined
                                    dense
                                    class="px-4"
                                    label="Descripción"
                                    :rules=required
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                             <v-col class="text-center">
                                <v-btn
                                    color="primary"
                                    @click="sendRequestAdveance"
                                >
                                    <v-icon>mdi-content-save</v-icon> ENVIAR SOLICITUD
                                </v-btn>
                                <v-btn 
                                    color='default'
                                    @click="dialog=false"
                                > 
                                    <v-icon>mdi-cancel</v-icon> Cerrar 
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import ExpandableFilters from '../general/ExpandableFilters'

export default {
    name:'QuoteComponent',
    components:{
        ExpandableFilters
    },
    data() {
        return {
            dataTable:[],
            finAccounts:[],
            headers:[
                {text: 'Cotización', align: 'start', value: 'quote_id'},
                {text: 'Nombre', align: 'start', value: 'name'},
                {text: 'Identificación', align: 'start', value: 'identification'},
                {text: 'Fecha', align: 'start', value: 'date'},
                {text: 'Estado', align: 'start', value: 'status'},
                {text: 'Total', align: 'start', value: 'total'},
                {text: 'Acciones', align: 'start', value: 'actions',align:'center'}
            ],
            filters:[
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                    v_model:'',
                    label: 'Cotización',
                    v_mask:'COT#########',
                    type:'input_text'
                },
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-2 pr-0 pr-sm-2',
                    v_model:'',
                    label: 'Identificación',
                    type:'input_text'
                },
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                    v_model:'',
                    label: 'Nombres',
                    type:'input_text'
                },
                {
                    cols:6,
                    class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                    v_model:'',
                    label: 'Estado',
                    type:'input_select',
                    text:'description',
                    value:'status_id',
                    items:[],
                    clearable:true
                }
            ],
            minVal:[
                v => v >= 0 || 'El valor debe ser 0 o mayor', 
                v => v <= parseFloat(this.advanceQuote.total) || `No mayor a $${this.advanceQuote.total}`,
                v => !!v || 'El campo es requerido'
            ],
            required:[
                v => !!v || 'El campo es requerido'
            ],
            dialog:false,
            status:'',
            totalItems:1,
            currentPage:1,
            itemsPerPage:20,
            options: {},
            advanceQuote:{}
        }
    },
    watch:{
        options: {
            handler () {
                this.getSupplierQuotes(this.filters)
            },
            deep: true,
        },
    },
    computed:{

        ...mapState('master',['loadingTable','tenantId','user'])

    },
    methods:{

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi','alertNotification']),

        handlePageChange(value) {
            this.currentPage = value
            this.getSupplierQuotes(this.filters,true)
        },

        getStatusQuote(){

            this.setUrl('lista-cotizacion-proveedor')
            this.requestApi({
				method: 'PUT',
				data :{}
			}).then(res =>{
                console.log(res)
                this.filters[3].items = res.data._embedded.lista_cotizacion_proveedor[0]
                this.finAccounts = res.data._embedded.lista_cotizacion_proveedor[1]
			})

        },

        getSupplierQuotes(filters, paginator){

            this.dataTable=[]
            filters.forEach(obj=>{
               if( this.currentPage > 1  && obj.v_model!='' && !paginator){
                   this.currentPage=1
                   return false;
               }
            })
            this.setLoadingTable(true)
            this.setUrl('lista-cotizacion-proveedor')
            this.requestApi({
				method: 'GET',
				data :{
                    page:this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    quoteId: filters[0].v_model,
                    identification: filters[1].v_model,
                    first_name: filters[2].v_model,
                    status: filters[3].v_model,
                    typeList: 'supplierQuotes',
                    paginator:true
                }
			}).then(res =>{
                console.log(res)
                this.totalItems = res.data.page_count
                this.dataTable = res.data._embedded.lista_cotizacion_proveedor
                this.setLoadingTable(false)
			})

        },

        requestAdvance(item){
            console.log(item)
            this.advanceQuote = item
            this.dialog= true
        },

        sendRequestAdveance(){

            if(!this.$refs.form_request_advance.validate())
                return false

            console.log(this.advanceQuote)
            
            this.setUrl('lista-cotizacion-proveedor')

            const {
                fin_account_id,
                quote_id,
                monto,
                description,
                payment_method_id
            } = this.advanceQuote

            this.requestApi({
				method: 'POST',
				data : {
                    fin_account_id,
                    quote_id,
                    monto,
                    description,
                    payment_method_id,
                    typeStore: 'storeAdvanceSupplier'
                }
			}).then(res =>{
                this.getSupplierQuotes(this.filters)
                this.dialog = false
                this.alertNotification({param:{html: res.data.res.msg}})

			})
            

        },

        manageQuote(){
            
        },
        
    },

    mounted(){
        this.getStatusQuote()
    }
    
}

</script>